import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "react-emotion";

import { url } from "src/shared/util";
import color from "src/styles/color";
import Logotype from "src/shared/Icons/Logotype";
import Button from "src/shared/Button";

import ControlPanel from "./ControlPanel";
import initializeModule from "./initializeModule";
import { useFile } from "./queries";
import Loading from "src/shared/Loading";
import TeethMovementsModal from "./TeethMovementsModal";
import SaveProjectModal from "./SaveProjectModal";
import { debounce } from "lodash";

type TxpFrame = {
  i: number;
  stageNum: number | "-";

  // maxilla and mandible not max and min lol
  isMx: boolean;
  isMn: boolean;
  isVisible: boolean;
};

type TxpPhase = {
  id: string;
  name: string;
  frames: TxpFrame[];
};

enum LoadingStatus {
  NotStarted = 0,
  SourceSelected = 1,
  Completed = 2,
}

// pre-computed list of phases and their associated stage, frame numbers
const buildPhasesAndFrames = (phases): TxpPhase[] => {
  const output: TxpPhase[] = [];

  let currCount = 0;
  for (let i = 0; i < phases.length; i++) {
    const phase = phases[i];
    const totalStages = Math.max(phase.mnFrames, phase.mxFrames);

    const txpPhase: TxpPhase = {
      id: phase.id,
      name: phase.name,
      frames: [],
    };

    for (let j = 1; j < totalStages + 1; j++) {
      if (j === 1 && (phase.mxFrames > 1 || phase.mnFrames > 1)) continue;
      txpPhase.frames.push({
        i: j,
        stageNum: currCount + j - 1,
        isMx: phase.mxFrames >= j,
        isMn: phase.mnFrames >= j,
        isVisible: j > 1 || i === 0,
      });
    }

    output.push(txpPhase);
    // subtract 1 because first stage of phrase does not count as separate stage
    currCount += totalStages - 1;
  }

  return output;
};

const Txp = (props: any) => {
  const {
    params: { fileID },
  } = props;

  // Handle error, loading state
  const [txpFileDetails, setTxpFileDetails] = useState<any>(null);
  const [localFile, setLocalFile] = useState<any>(null);
  const [wasmModule, setWasmModule] = useState<any>(null);
  const [loadingStatus, setLoadingStatus] = useState(
    fileID === "localfile"
      ? LoadingStatus.NotStarted
      : LoadingStatus.SourceSelected
  );
  const [loadProgress, setLoadProgress] = useState("");

  const [phases, setPhases] = useState<TxpPhase[] | null>(null);
  const [currPhase, setCurrPhase] = useState<TxpPhase | null>(null);
  const [currFrame, setCurrFrame] = useState<TxpFrame | null>(null);
  const [selectLastKeyframe, setSelectLastKeyframe] = useState(false);

  const [selectedTooth, setSelectedTooth] = useState<any>(null);
  const [toothInfo, setToothInfo] = useState<any>(null);
  const [toothTranslationStep, setToothTranslationStep] = useState(0);
  const [toothRotationStep, setToothRotationStep] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  const [, fileError, file] = useFile(fileID);
  const [showTeethMovements, setShowTeethMovements] = useState(false);
  const [showSaveProjectModal, setShowSaveProjectModal] = useState(false);
  const [spacingVisible, setSpacingVisible] = useState(false);
  const [addAdjPhaseButtonEnabled, setAddAdjPhaseButtonEnabled] = useState(
    true
  );
  const [submitChangedButtonEnabled, setSubmitChangedButtonEnabled] = useState(
    false
  );

  const [cbctAxialLowBound, setCBCTAxialLowBound] = useState(0);
  const [cbctAxialLowValue, setCBCTAxialLowValue] = useState(0);
  const [cbctAxialHighValue, setCBCTAxialHighValue] = useState(0);
  const [cbctAxialHighBound, setCBCTAxialHighBound] = useState(0);
  const [cbctSagittalLowBound, setCBCTSagittalLowBound] = useState(0);
  const [cbctSagittalLowValue, setCBCTSagittalLowValue] = useState(0);
  const [cbctSagittalHighValue, setCBCTSagittalHighValue] = useState(0);
  const [cbctSagittalHighBound, setCBCTSagittalHighBound] = useState(0);
  const [cbctCoronalLowBound, setCBCTCoronalLowBound] = useState(0);
  const [cbctCoronalLowValue, setCBCTCoronalLowValue] = useState(0);
  const [cbctCoronalHighValue, setCBCTCoronalHighValue] = useState(0);
  const [cbctCoronalHighBound, setCBCTCoronalHighBound] = useState(0);

  const [cbctVolumeHardBonesLevel, setCBCTVolumeHardBonesLevel] = useState(0);
  const [cbctVolumeSoftBonesLevel, setCBCTVolumeSoftBonesLevel] = useState(0);
  const [cbctVolumeHardBonesOpacity, setCBCTVolumeHardBonesOpacity] = useState(
    0
  );
  const [cbctVolumeSoftBonesOpacity, setCBCTVolumeSoftBonesOpacity] = useState(
    0
  );
  const [cbctSurfaceBonesLevel, setCBCTSurfaceBonesLevel] = useState(0);
  const [cbctSurfaceOpacity, setCBCTSurfaceOpacity] = useState(0);
  const [cbctSurfaceReduction, setCBCTSurfaceReduction] = useState(0);
  const [cbctJawAlignment, setCBCTJawAlignment] = useState(false);
  const [cbctSurfaceMode, setCBCTSurfaceMode] = useState(false);
  const [cbctAvailable, setCBCTAvailable] = useState(false);

  useEffect(() => {
    if (!fileError || fileID === "localfile") return;

    setError(
      `Error loading file ID ${fileID}: ${fileError.message ||
        JSON.stringify(fileError)}`
    );
  }, [fileError, setError, fileID]);

  const hideNotification = useCallback(
    debounce(() => {
      setNotification(null);
    }, 5000),
    []
  );

  useEffect(() => {
    if (notification) {
      hideNotification();
    }
  }, [notification, hideNotification]);

  const getTxpFileDetails = async () => {
    if (txpFileDetails) return;

    if (fileID === "localfile") {
      return;
    }

    const fileURL = url(`/api/v2/files/${fileID}?txp=true`);
    const headers = {
      authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
    };
    const req = new Request(fileURL, { headers });
    const res = await fetch(req);

    if (res.status === 200) {
      const body = await res.json();
      setTxpFileDetails(body);
    } else {
      setError(`Error fetching file URL: ${JSON.stringify(res)}`);
    }
  };

  const handleFileSelect = event => {
    const localFileMeta = event.target.files[0];
    if (localFileMeta) {
      console.log(localFileMeta);
      setLoadingStatus(LoadingStatus.SourceSelected);
      setLocalFile(localFileMeta);
    }
  };

  const fileInputRef = document.getElementById("fileInput");
  const handleButtonClick = () => {
    if (fileInputRef) {
      fileInputRef.click();
    }
  };

  const onLoadSuccess = () => setLoadingStatus(LoadingStatus.Completed);
  const txpCanvas = document.getElementById("canvas");

  useEffect(() => {
    if (!txpCanvas) return;
    const focusCanvas = () => {
      txpCanvas.focus();
    };
    // make sure to focus canvas at all times so that key bindings like
    // shift + click work
    focusCanvas();
    txpCanvas.addEventListener("click", focusCanvas);
    return () => {
      txpCanvas.removeEventListener("click", focusCanvas);
    };
  }, [txpCanvas]);

  useEffect(() => {
    if (!wasmModule || loadingStatus < LoadingStatus.Completed) return;
    const bodyKeyDown = e => {
      if (e.repeat) return;
      wasmModule.webKeyboardEvent(e.key, e.shiftKey);
    };
    document.body.addEventListener("keydown", bodyKeyDown);
    return () => {
      document.body.removeEventListener("keydown", bodyKeyDown);
    };
  }, [wasmModule, loadingStatus]);

  useEffect(() => {
    if (!txpCanvas) {
      return;
    }
    if (!txpFileDetails && !localFile) {
      return;
    }
    if (wasmModule) {
      return;
    }

    setWasmModule(
      initializeModule(
        txpFileDetails ? txpFileDetails.url : "",
        localFile,
        txpCanvas,
        onLoadSuccess,
        setError,
        setToothInfo,
        setSelectedTooth,
        setLoadProgress,
        setSpacingVisible,
        setCBCTAxialLowBound,
        setCBCTAxialLowValue,
        setCBCTAxialHighValue,
        setCBCTAxialHighBound,
        setCBCTSagittalLowBound,
        setCBCTSagittalLowValue,
        setCBCTSagittalHighValue,
        setCBCTSagittalHighBound,
        setCBCTCoronalLowBound,
        setCBCTCoronalLowValue,
        setCBCTCoronalHighValue,
        setCBCTCoronalHighBound,
        setCBCTVolumeHardBonesLevel,
        setCBCTVolumeSoftBonesLevel,
        setCBCTVolumeHardBonesOpacity,
        setCBCTVolumeSoftBonesOpacity,
        setCBCTSurfaceBonesLevel,
        setCBCTSurfaceOpacity,
        setCBCTSurfaceReduction,
        setCBCTJawAlignment,
        setCBCTSurfaceMode,
        setCBCTAvailable
      )
    );
  }, [txpFileDetails, localFile, txpCanvas, setError, wasmModule]);

  useEffect(() => {
    if (!wasmModule || loadingStatus < LoadingStatus.Completed) return;

    const phases = JSON.parse(wasmModule.webGetPhases());
    const txpPhases = buildPhasesAndFrames(phases);
    setPhases(txpPhases);
    setCurrPhase(txpPhases[0]);
    setCurrFrame(txpPhases[0].frames[0]);
  }, [wasmModule, loadingStatus]);

  const onClickStage = (phase: TxpPhase, frame: TxpFrame) => {
    if (selectedTooth) return;
    setCurrFrame(frame);
    if (currPhase && phase.id === currPhase.id) {
      // webShowFrame expects 0-index (frame.i is 1-indexed)
      wasmModule.webShowFrame(frame.i - 1);
    } else {
      wasmModule.webSelectPhase(phase.id, frame.isVisible ? frame.i - 1 : -1);
      for (let i = 0; phases && i < phases.length; i++) {
        const phaseLookup = phases[i];
        if (phase.id === phaseLookup.id) {
          setCurrPhase(phase);
        }
      }
    }
  };

  const onClickPhase = (phase: TxpPhase) => {
    if (selectedTooth) return;
    wasmModule.webSelectPhase(phase.id, -1);
    for (let i = 0; phases && i < phases.length; i++) {
      const phaseLookup = phases[i];
      if (phase.id === phaseLookup.id) {
        setCurrPhase(phase);
      }
    }
  };

  useEffect(() => {
    if (
      !wasmModule ||
      loadingStatus < LoadingStatus.Completed ||
      !selectLastKeyframe ||
      !currPhase
    )
      return;
    var frames = currPhase.frames;
    if (frames.length > 0) {
      var newFrame = frames[frames.length - 1];
      setCurrFrame(newFrame);
    }
    setSelectLastKeyframe(false);
  }, [wasmModule, loadingStatus, selectLastKeyframe, currPhase, currFrame]);

  useEffect(() => {
    if (!wasmModule || loadingStatus < LoadingStatus.Completed) return;
    wasmModule.webOnSelectPhase(phaseId => {});
    wasmModule.webOnKeyFramesChanged(() => {
      var phases = buildPhasesAndFrames(JSON.parse(wasmModule.webGetPhases()));
      setPhases(phases);
      setCurrPhase(phases[phases.length - 1]);
      setSelectLastKeyframe(true);
    });
    wasmModule.webOnTreatmentPhasesChanged(() => {
      var phases = buildPhasesAndFrames(JSON.parse(wasmModule.webGetPhases()));
      setPhases(phases);
      setCurrPhase(phases[phases.length - 1]);
      setSelectLastKeyframe(true);
    });
    wasmModule.webOnToothMoved(toothId => {
      setToothInfo({
        toothNum: toothId,
        // @ts-ignore
        ...JSON.parse(wasmModule.webGetToothMovement(toothId)),
      });
    });
    wasmModule.webOnNotification(message => {
      setNotification(message);
    });
    wasmModule.webOnToothMovementStepChanged((rotation, translaion) => {
      setToothRotationStep(rotation);
      setToothTranslationStep(translaion);
    });
    setToothRotationStep(wasmModule.webGetToothRotationStep());
    setToothTranslationStep(wasmModule.webGetToothTranslationStep());
    wasmModule.webOnDoctorsAdjustmentsChanged(
      (addAdjPhaseEnabled, submitChangesEnabled) => {
        setAddAdjPhaseButtonEnabled(addAdjPhaseEnabled);
        setSubmitChangedButtonEnabled(submitChangesEnabled);
      }
    );
  }, [wasmModule, loadingStatus]);

  getTxpFileDetails();

  return (
    <Container
      onMouseUp={() => {
        if (!wasmModule || loadingStatus < LoadingStatus.Completed) return;
        wasmModule.webPreviewMouseReleased();
      }}
    >
      {error && <TxpError>{error}</TxpError>}
      {notification && <TxpNotification>{notification}</TxpNotification>}
      {showTeethMovements && (
        <TeethMovementsModal
          onClose={() => {
            setShowTeethMovements(false);
          }}
          wasmModule={wasmModule}
          phase={currPhase}
          frame={currFrame}
        />
      )}
      {showSaveProjectModal && (
        <SaveProjectModal
          onClose={() => {
            setShowSaveProjectModal(false);
          }}
          wasmModule={wasmModule}
          patient={file && file.user}
          txpFileDetails={txpFileDetails}
          changeFileName={(fileName, fileDescription) => {
            txpFileDetails.filename = fileName;
            txpFileDetails.description = fileDescription;
            setTxpFileDetails(txpFileDetails);
          }}
          submitChangedButtonEnabled={submitChangedButtonEnabled}
        />
      )}
      {/* Do notttt change the id="canvas" here because visual tool kit (our /scripts/weabassembly.js file) hardcodes this value to use to find canvas */}
      <canvas className={canvasCss} id="canvas" tabIndex={1} />
      <Logotype className={logo} />
      <LoadingScreen
        style={{
          display:
            loadingStatus === LoadingStatus.SourceSelected ? "flex" : "none",
          flexFlow: "column",
        }}
      >
        <Loading color="white" />
        <div>{loadProgress}</div>
      </LoadingScreen>
      <LoadingScreen
        style={{
          display:
            fileID === "localfile" && loadingStatus === LoadingStatus.NotStarted
              ? "flex"
              : "none",
          flexFlow: "column",
        }}
      >
        <Button style={{ marginTop: "20px" }} onClick={handleButtonClick}>
          Open a local txp file
        </Button>
        <input
          type="file"
          style={{ display: "none" }}
          id="fileInput"
          onChange={handleFileSelect}
        />
      </LoadingScreen>
      <ControlPanel
        wasmModule={wasmModule}
        patient={file && file.user}
        toothInfo={toothInfo}
        toothRotationStep={toothRotationStep}
        toothTranslationStep={toothTranslationStep}
        setShowTeethMovements={setShowTeethMovements}
        setShowSaveProjectModal={val => {
          wasmModule.webResetToothSelection();
          setShowSaveProjectModal(val);
        }}
        addAdjPhaseButtonEnabled={addAdjPhaseButtonEnabled}
        submitChangedButtonEnabled={submitChangedButtonEnabled}
        spacingVisible={spacingVisible}
        cbctAxialLowBound={cbctAxialLowBound}
        cbctAxialLowValue={cbctAxialLowValue}
        cbctAxialHighValue={cbctAxialHighValue}
        cbctAxialHighBound={cbctAxialHighBound}
        cbctSagittalLowBound={cbctSagittalLowBound}
        cbctSagittalLowValue={cbctSagittalLowValue}
        cbctSagittalHighValue={cbctSagittalHighValue}
        cbctSagittalHighBound={cbctSagittalHighBound}
        cbctCoronalLowBound={cbctCoronalLowBound}
        cbctCoronalLowValue={cbctCoronalLowValue}
        cbctCoronalHighValue={cbctCoronalHighValue}
        cbctCoronalHighBound={cbctCoronalHighBound}
        cbctVolumeHardBonesLevel={cbctVolumeHardBonesLevel}
        cbctVolumeSoftBonesLevel={cbctVolumeSoftBonesLevel}
        cbctVolumeHardBonesOpacity={cbctVolumeHardBonesOpacity}
        cbctVolumeSoftBonesOpacity={cbctVolumeSoftBonesOpacity}
        cbctSurfaceBonesLevel={cbctSurfaceBonesLevel}
        cbctSurfaceOpacity={cbctSurfaceOpacity}
        cbctSurfaceReduction={cbctSurfaceReduction}
        cbctJawAlignment={cbctJawAlignment}
        cbctSurfaceMode={cbctSurfaceMode}
        cbctAvailable={cbctAvailable}
      />
      {phases && phases.length && currFrame && currPhase && (
        <PhaseControls>
          <PhaseLabels />
          {phases.map(txpPhase => {
            return (
              <Phase
                key={txpPhase.id}
                phase={txpPhase}
                onSetStage={onClickStage}
                onClickPhase={onClickPhase}
                selectedPhase={currPhase}
                selectedFrame={currFrame}
                wasmModule={wasmModule}
              />
            );
          })}
        </PhaseControls>
      )}
    </Container>
  );
};

const TxpError = styled.div`
  color: red;
  position: absolute;
  background-color: pink;
  padding: 15px;
  width: 400px;
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 6;
`;

const TxpNotification = styled.div`
  color: ${color.gray1};
  position: absolute;
  background-color: #333;
  padding: 15px;
  width: 400px;
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 6;
`;

const PhaseLabels = () => {
  return (
    <div className={archLabels}>
      <div>
        <div>Maxilla</div>
      </div>
      <div>
        <div>Mandible</div>
      </div>
      <div />
    </div>
  );
};

type PhaseProps = {
  phase: TxpPhase;
  onSetStage: (phase: TxpPhase, frame: TxpFrame) => void;
  onClickPhase: (phase: TxpPhase) => void;
  selectedPhase: TxpPhase;
  selectedFrame: TxpFrame;
  wasmModule: any;
};

const Phase: React.FC<PhaseProps> = ({
  phase,
  onSetStage,
  onClickPhase,
  selectedPhase,
  selectedFrame,
  wasmModule,
}) => {
  return (
    <div className={phaseContainer}>
      <div
        className={phaseLabel}
        style={{
          textDecorationLine:
            selectedPhase.id === phase.id ? "underline" : "none",
          pointerEvents: phase.frames.length > 0 ? "none" : "auto",
        }}
        onClick={() => {
          onClickPhase(phase);
        }}
      >
        {phase.name}
      </div>
      {phase.frames.map(txpFrame => {
        return (
          <Stage
            key={`${phase.id}+${txpFrame.i}`}
            isSelected={
              selectedPhase.id === phase.id && selectedFrame.i === txpFrame.i
            }
            onClick={() => {
              onSetStage(phase, txpFrame);
            }}
            onMouseMove={event => {
              if (event.buttons === 1)
                wasmModule.webPreviewFrame(
                  phase.id,
                  txpFrame.isVisible ? txpFrame.i - 1 : -1
                );
            }}
            showMn={txpFrame.isMn}
            showMx={txpFrame.isMx}
            number={txpFrame.stageNum}
            isVisible={txpFrame.isVisible}
          />
        );
      })}
    </div>
  );
};

const Stage = ({
  showMn,
  showMx,
  onClick,
  number,
  isSelected,
  onMouseMove,
  isVisible,
}) => {
  return (
    <button
      style={{ backgroundColor: isSelected ? "#C94C1B" : undefined }}
      type="button"
      onClick={onClick}
      className={stageButton}
      onMouseMove={onMouseMove}
    >
      <div>{isVisible && showMx && <div className={whiteRect} />}</div>
      <div>{isVisible && showMn && <div className={whiteRect} />}</div>
      <div>
        {isVisible && <div style={{ color: color.gray1 }}>{number}</div>}
      </div>
    </button>
  );
};

const phaseLabel = css`
  position: absolute;
  z-index: 3;
  top: 15px;
  left: 5px;
  color: white;
  pointer-events: none;
  text-decoration: underline;

  &:hover {
    color: ${color.blue};
    cursor: pointer;
  }
`;

const canvasCss = css`
  z-index: 0;
  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  background-color: black;
`;

const LoadingScreen = styled.div`
  position: absolute;
  color: white;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhaseControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  border-radius: 4px;
  height: 125px;
  width: calc(100vw - 40px);
  background-color: #333;
  z-index: 1;
  opacity: 0.6;
  overflow-x: auto;

  display: flex;
  flex-flow: row;

  user-select: none;
`;

const stageButton = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: none;
  border: none;
  height: 100%;
  width: 60px;
  cursor: pointer;
  padding: 25px 5px 0 5px;

  &:hover {
    background-color: #c94c1b;
  }

  > div {
    height: 25px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 12px;
  }
`;

const archLabels = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: none;
  border: none;
  height: 100%;
  width: 80px;
  padding: 25px 5px 0 5px;
  margin: 0 10px 0 30px;

  > div {
    height: 25px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    color: white;

    > div {
      color: white;
    }
  }
`;

const whiteRect = css`
  height: 5px;
  width: 100%;
  background-color: white;
`;

const phaseContainer = css`
  display: flex;
  flex-flow: row;
  margin-right: 20px;
  position: relative;
  min-width: 50px;
`;

const logo = css`
  position: absolute;
  left: 35px;
  top: 35px;
  z-index: 5;
`;

export default Txp;
